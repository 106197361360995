<template>
	<v-dialog
		v-model="dialog"
		fullscreen
		hide-overlay
		persistent
		width="300"
	>	
		<v-container fluid fill-height style="background-color: rgba(238, 238, 238, 1);">
			<v-row justify="center" align="center">
				<v-col cols="12" align="center">
					<v-img
							class="mb-6"
							width="170"
							
							:src="require('@/assets/logo_gavcom.png')"
						></v-img>
					<h2 class="font-weight-bold grey--text pb-4">Cargando los datos del usuario...</h2>
					<v-progress-circular indeterminate size="64" color="primary"></v-progress-circular>
				</v-col>
			</v-row>
		</v-container>
	</v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
// Mixins
import MainExternalData from '@/mixins/main-external-data'

export default {
	mixins: [MainExternalData],
	name: "GavcomInitialData",

	data() {
		return {
			dialog: null
		}
	},

	computed: {
    ...mapGetters(['activeSession'])
  },

	async created() {
		this.dialog = true
    if (this.activeSession) {
			if (await this.getInitialInfo()) {
				if (this.userData.passwordExpired) {
					this.$router.push({ name: 'ChangePassword' })
				} else {
					this.$router.push({ name: 'Home' })
				}
			} 
		}
	},

	methods: {
		async getInitialInfo() {
			let me=this;
			if (!await me.getUserInfo()) {
				return false;
			}
			if (!await me.getCompanyInfo()) {
				return false;
			}
			return true;
		},
		
		async getCompanyInfo() {
			let me=this;
			let request= {
				'branchOfficeId': me.companyData.branchOfficeId,
			}
			request = Object.assign({}, me.baseRequest, request)
			let objectData;
			await me.getObjectResponse('api/Account/GetCompanyInfo', request).then(data => {
				objectData = data;
			}).catch(function(error){
				me.$swal(me.swalConfig.errorTitle, error.message, 'error');
			});

			if (!objectData) {
				return false;
			}

			me.$store.commit("SET_COMPANY_DATA", objectData);
			return true;
		},

		async getUserInfo() {
			let me=this;
			let objectData;
			await me.getObjectResponse('api/Account/GetUserInfo', me.baseRequest).then(data => {
				objectData = data;
			}).catch(function(error){
				me.$swal(me.swalConfig.errorTitle, error.message, 'error');
			});

			if (!objectData) {
				return false;
			}

			me.$store.commit("SET_USER_DATA", objectData);
			return true;
		}
	},
}
</script>